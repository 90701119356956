import React,{useState,useEffect} from 'react';
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
  } from '@fortawesome/free-brands-svg-icons'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import SectionButtonExternal from "../components/SectionButtonExternal"

const GridLayout = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
`;
const MemberRow = styled.div`
    padding:50px 150px 50px 0px;
    width:100%;
    height:auto;
    display:flex;
    // grid-template-columns: 0.65fr 1.35fr;
    // grid-template-rows: auto;
    position:relative;
    // grid-row-gap: 100px;
    // grid-column-gap: 50px;
     @media screen and (max-width: 991px) {        
        padding:25px 0px 25px 0px;
    }
    @media screen and (max-width: 479px) {
        display:flex;
        flex-direction:column;
        padding:40px 0px 40px 0px;
    }
    // flex-direction:row;
    // justify-content:flex-start;
    
`;
const MemberImageContainer = styled.div`
    width: 40%;
    margin-right:50px;
    height: 400px;
    border-radius: 30px;
    overflow:hidden;
    @media screen and (max-width: 991px) {
        margin-right:20px;
    }
    @media screen and (max-width: 479px) {
        margin-right:0px;
        width: 100%;
        height: 380px;
    }
`;
const MemberImage = styled.img`
    object-fit:cover;
    object-position:top;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    margin-bottom:0px;
    transition:all 0.3s ease;
    transform:${props => props.imageHover? 'scale(1.1)':'scale(1)'};
    @media screen and (max-width: 991px) {
        transform:scale(1);
    }
`;
const MemberDetailsContainer = styled.div`
    position:relative;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    @media screen and (max-width: 991px) {
        width:60%;
    }
    @media screen and (max-width: 479px) {
        margin-top:20px;
        width:100%;
    }
`;
const MemberNameBlock = styled.div`
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:flex-start; 
    align-items:flex-end;
`;
const Name = styled.h1`
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin-bottom:0px;
    @media screen and (max-width: 991px) {
        font-size: 40px;
    }
    @media screen and (max-width: 479px) {
        font-size: 30px;
    }
`;
const Designation = styled.h3`
    margin-top:5px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #3c57a1;
`;
const SocialWrap = styled.div`
    display:flex;
    margin-left:auto;
`;
const SocialIcon = styled.a`
    text-decoration:none;
    font-size:20px;
    color:#3c57a1;
    margin-right:15px;
    cursor:pointer;
    margin-bottom:0;
`;
const AbsDes = styled.div`
    position:relative;
    transition:1s ease;
    height:auto;
    max-height:${props => props.hoverState? '900px':'400px'};
    margin-bottom:${props => props.hoverState? '20px':'0px'};
    overflow:hidden;
    @media screen and (max-width: 991px) {
        max-height:${props => props.hoverState? '150vh':'200px'};
    }
    @media screen and (max-width: 479px) {
        margin-bottom:20px;
    }
`;
const MemberDescription=styled.div`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    width:100%;
    opacity:0.8;
    width:auto;
    @media screen and (max-width: 479px) {
        width:100%;
    }
    & span{
        font-weight:500;
        color:#2e3439;
    }
`;
const ButtonLink = styled.div`
    text-decoration:none;
    outline:none;
    margin-top:auto;
    margin-left:auto;
    margin-right:0px;
    margin-bottom:10px;
    position:relative;
    z-index:0;
`;
const ButtonWrap=styled.div`
    outline:none;
    border:none;
    background:transparent;
}
`;
const ButtonOverlay=styled.div`
    padding: 4px 33px 4px 33px;
    border-radius:30px;
    border: solid 1px #3c57a1;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #3c57a1;
    position:relative;
    background: linear-gradient(to left, transparent 50%, #3c57a1 50%) right;
    background-size: 205%;
    transition: all .5s ease-out;
    cursor:pointer;
    &:hover{
        background-position: left bottom;
        color:#fff;
    }
`;
const MemberRowComponent = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const [imageHover,setImageHover] = useState(true);
    useEffect(() => {
        setHoverState(false)
        setImageHover(false)
      }, []);
    const toggleDescription=()=>{
        setHoverState(!hoverState)        
    }
    const handleCloseDescription=()=>{
        setHoverState(false)        
    }
    const imageHoverIn=()=>{
        setImageHover(true)        
    }
    const imageHoverOut=()=>{
        setImageHover(false)        
    }
    return (
        <MemberRow>
            <MemberImageContainer onMouseEnter={imageHoverIn} onMouseLeave={imageHoverOut}>
                <MemberImage src={props.data.teamDetails.image.mediaItemUrl} imageHover={imageHover}></MemberImage>
            </MemberImageContainer>
            
            <MemberDetailsContainer >
                <MemberNameBlock>
                    <Name>{props.data.teamDetails.name}</Name>
                    {props.data.teamDetails.linkedinUrl?
                    <SocialWrap>
                        <SocialIcon href={props.data.teamDetails.linkedinUrl} target="_blank">
                            <FontAwesomeIcon icon={faLinkedin}/>
                        </SocialIcon>
                    </SocialWrap>:''}
                </MemberNameBlock>
                {props.data.teamDetails.designation?
                    <Designation>{props.data.teamDetails.designation}</Designation>
                    :''
                }   
                {props.data.teamDetails.description?
                    <AbsDes hoverState={hoverState} onClick={handleCloseDescription}>
                        {/* {!hoverState? */}
                            <MemberDescription dangerouslySetInnerHTML={{ __html: !hoverState? props.data.teamDetails.description.substr(0,530)+"..." :props.data.teamDetails.description.substr(0,props.data.teamDetails.description.length)}} />
                        {/* :   <MemberDescription dangerouslySetInnerHTML={{ __html: props.data.teamDetails.description.substr(0,props.data.teamDetails.description.length) }} /> */}
                        {/* } */}
                    </AbsDes>
                :''
                }
                {/* <SectionParagraph>Sunil Wadhwani is a Pittsburgh-based technology entrepreneur, investor, and philanthropist. He has committed the last 40 years of his life to scaling impactful technology and healthcare organizations through his professional and philanthropic efforts. </SectionParagraph> */}
                <ButtonLink onClick={toggleDescription} hoverState={hoverState}>
                    <ButtonWrap>
                        <ButtonOverlay>{hoverState?"Read Less":"Read More"}</ButtonOverlay>
                    </ButtonWrap>
                </ButtonLink>       
                {/* <SectionButtonExternal left={"auto"} right={"0px"} top={"auto"} bottom={"10px"} onClick={handleDescription}>Read More</SectionButtonExternal> */}
            </MemberDetailsContainer>
        </MemberRow>
    );
};

export default MemberRowComponent;