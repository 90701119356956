import React, { useState, useEffect, useRef } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import styled from "styled-components";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import './expandBox.css'

const ContentWrap = styled.div`
  display:flex;
  background:${props => props.isexpanded ? 'white' : 'transparent'};
  width:100%;
  height:auto;
  flex-shrink: 0;
  flex-direction:${props => props.isexpanded ? 'row' : 'column'};
  margin-top:${props => props.isexpanded ? '1rem' : '0px'};
  transition:all 0.3s ease;
  cursor:pointer;
  position:relative;
  justify-content:flex-start;
  align-items:flex-start;
  border-radius: 0.75rem;
  overflow-y:${props => props.isexpanded ? 'scroll' : 'hidden'};
  &:hover{
    transform:${props => props.isexpanded ? 'scale(1)' : 'scale(1.05)'};
  }
  @media only screen and (max-width: 991px) {
    flex-direction:column;
    &:hover{
      transform:${props => props.isexpanded ? 'scale(1)' : 'scale(1)'};
    }
  }
  @media only screen and (max-width: 767px) {
    
  }
  @media only screen and (max-width: 479px) {
    
  }
`;
const MemberImage = styled.img`
    // box-shadow:${props => props.isexpanded ? 'none' : '0 25px 50px -12px rgb(0 0 0 / 0.25)'};
    // &:hover{
    //   box-shadow:${props => props.isexpanded ? 'none' : '0 25px 50px -12px rgb(0 0 0 / 0.25)'};
    // }
    width:${props => props.isexpanded ? '300px' : '100%'};
    height:14rem;
    display:flex;
    flex-shrink:0;
    transition:all 0.3s ease;
    object-fit:cover;
    object-position:50% 20%;
    margin-bottom:0px;
    border-radius:0.75rem;
    @media only screen and (max-width: 991px) {
      width:${props => props.isexpanded ? '300px' : '100%'};
      height:${props => props.isexpanded ? '14rem' : '13rem'};
    }
    @media only screen and (max-width: 767px) {
      height:12rem;
    }
    @media only screen and (max-width: 479px) {
      height:10rem;
      width:10rem;
    }
`;
const ContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding-left:0px;
    margin-top:${props => props.isexpanded ? '1rem' : '0px'};
    padding-left:${props => props.isexpanded ? '20px' : '5px'};
    // overflow-y:${props => props.isexpanded ? 'scroll' : 'hidden'};
    @media only screen and (max-width: 479px) {
      padding-left:0px;
    }
`;
const Name = styled.h3`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  margin-bottom:5px;
  color:#2e3439;
  margin-top:${props => props.isexpanded ? '0px' : '0.5rem'};
  @media only screen and (max-width: 991px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;
const Designation = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  margin-bottom:5px;
  color:#3c57a1;
  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
const Description = styled.div`
    margin-top:0.5rem;
    text-align:left;
    line-height: 1.25;
    width:100%;
    font-size:16px;
    color:#616161;
    overflow-y:auto;
    @media only screen and (max-width: 479px) {
      font-size:14px;
    }
`;
const MemberNameBlock = styled.div`
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:flex-start; 
    align-items:flex-start;
`;
const SocialWrap = styled.div`
    display:flex;
    margin-left:auto;
`;
const SocialIcon = styled.a`
    text-decoration:none;
    font-size:20px;
    color:#3c57a1;
    margin-right:15px;
    cursor:pointer;
    margin-bottom:0;
`;
const NameDesBlock = styled.div`
  width:auto;
  height:auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start; 
  align-items:flex-start;
`;
function Content({ data, disabled, isexpanded }) {
  return (
    <ContentWrap as={motion.div} isexpanded={isexpanded} style={{ opacity: disabled ? 0.2 : 1 }}>
      <MemberImage src={data.teamDetails.image.mediaItemUrl} alt={data.teamDetails.name} layoutId="memberImg" isexpanded={isexpanded} />

      <ContentContainer isexpanded={isexpanded}>
        <MemberNameBlock>
          <NameDesBlock>
            <Name isexpanded={isexpanded}>{data.teamDetails.name}</Name>
            <Designation>{data.teamDetails.designation}</Designation>
          </NameDesBlock>

          {isexpanded && data.teamDetails.linkedinUrl ?
            <SocialWrap>
              <SocialIcon href={data.teamDetails.linkedinUrl} target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
              </SocialIcon>
            </SocialWrap> : ''}
        </MemberNameBlock>

        {isexpanded && data.teamDetails.description &&
          <Description as={motion.div}
            initial={{ opacity: 0, top: "-6rem" }}
            animate={{ opacity: 1, top: "0rem" }}
            dangerouslySetInnerHTML={{ __html: data.teamDetails.description }}
          />
        }
      </ContentContainer>

    </ContentWrap>
    // <motion.h1
    //   className="title"
    //   layoutId="title"
    //   style={{ opacity: disabled ? 0.2 : 1 }}
    // >
    //   {day}
    // </motion.h1>
  );
}

function ExpandedCard({ children, onCollapse }) {
  return (
    <motion.div
      className="expandableCard"
      layoutId="expandable-card"
    >
      {children}
      <div className="closeWrap" onClick={onCollapse}>
        <FaTimes className="faTimes" />
      </div>
    </motion.div>
  );
}

function CompactCard({ children, onExpand, disabled }) {
  return (
    <motion.div
      className="compactCard"
      layoutId="expandable-card"
      onClick={disabled ? undefined : onExpand}
    >
      {children}
    </motion.div>
  );
}

function MemberBlock({ data, onCollapse, onExpand, disabled }) {
  const [isexpanded, setisexpanded] = useState(false);
  const modalInnerRef = useRef(null);
  // useEffect(() => {
  //   if (modalInnerRef.current) {
  //     if (isexpanded) {
  //       document.documentElement.style.overflow = 'hidden';
  //       disableBodyScroll(modalInnerRef.current);
  //     } else {
  //       enableBodyScroll(modalInnerRef.current);
  //     }
  //   }
  //   return () => {
  //     clearAllBodyScrollLocks();
  //     document.documentElement.style.overflow = null;
  //   };

  // }, [isexpanded]);

  const collapseMember = () => {
    setisexpanded(false);
    onCollapse();
    document.body.style.overflow = 'unset';
  };

  const expandMember = () => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
    setisexpanded(true);
    onExpand();
  };


  return (
    <div style={{ width: "100%", cursor: "pointer" }} ref={modalInnerRef}>
      <AnimateSharedLayout>
        {isexpanded ? (
          <ExpandedCard onCollapse={collapseMember}>
            <Content disabled={disabled} isexpanded={isexpanded} data={data} />
          </ExpandedCard>
        ) : (
          <CompactCard onExpand={expandMember} disabled={disabled}>
            <Content disabled={disabled} isexpanded={isexpanded} data={data} />
          </CompactCard>
        )}
      </AnimateSharedLayout>
    </div>
  );
}

export default function ExpandBox(props) {
  const [expandedMember, setExpandedMember] = useState();
  return (
    <MemberBlock
      key={props.data.teamDetails.name}
      data={props.data}
      disabled={expandedMember !== props.data.teamDetails.name && expandedMember !== undefined}
      onExpand={() => setExpandedMember(props.data.teamDetails.name)}
      onCollapse={() => setExpandedMember()}
    />
  );
}
