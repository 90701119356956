import React,{useState,useEffect} from 'react';
import styled from "styled-components"
import Priyanka from "../images/DrMadhurMaheshwari.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
  } from '@fortawesome/free-brands-svg-icons'
const Fellow = styled.div`
    display:flex;
    flex-direction:column;
    width: 28.33%;
    margin:20px 2.5% 20px 2.5%;
    height: auto;
    border-radius: 30px;
    overflow:hidden;
    position:relative;
    @media screen and (max-width: 991px) {
        width: 45%;
        margin:20px 2.5% 20px 2.5%;
    }
    @media screen and (max-width: 479px) {
        width: 97.5%;
        margin:40px 2.5% 40px 2.5%;
    }
`;

const FellowImageContainer = styled.div`
    display:flex;
    position:relative;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    border-radius:30px;
    overflow:hidden;
`;
const FellowImage= styled.img`
    filter:${props => props.hoverState? 'blur(10px)':'blur(0px)'};
    width:100%;
    height: 280px;
    object-fit:cover;
    object-position:50% 20%;   
    margin-bottom:0px;
    transition:all 0.5s ease;
    border-radius:30px;
`;
const Overlay = styled.div`
    transition:all 0.5s ease;
    border-radius:30px;
    width:100%;
    height:${props => props.hoverState? '100%':'0%'};
    display:flex;
    flex-direction:column;
    position:absolute;
    background-color: rgba(0,0,0,0.7);
    top:0%;
    left:0%;
    padding:20px;
    overflow:hidden;
    transform:${props => props.hoverState? 'translateY(0px)':'translateY(-50px)'};
    z-index:5;
`;

const OverlayNameBlock = styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-between;
`;

const OverlaySocialIcon = styled.a`
    text-decoration:none;
    margin-bottom:0;
    color: #fff;
    font-size:20px;
    display: flex;
    margin-left:auto;
`;
const Description = styled.p`
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: 300;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom:0px;
    margin-top:20px;
`;
const OverlayContainer = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    left:0%;
    top:0%;
    z-index:10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
const Name= styled.h1`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    margin-bottom:5px;
    color:rgba(46,52,57,0.9);
    transform:${props => props.hoverState? 'translateY(90px)':'translateY(0)'};
    transition:all 0.5s ease;
    margin-top:20px;
`;
const Designation = styled.h3`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color:rgba(46,52,57,0.9);
    margin-bottom:0px;
    transform:${props => props.hoverState? 'translateY(50px)':'translateY(0)'};
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        font-size:14px;
    }
    @media screen and (max-width: 479px) {
        font-size:16px;
    }
`;
const FellowTile = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverIn= () =>{
        setHoverState(true)
    }
    const toggleHoverOut= () =>{
        setHoverState(false)
    }
    useEffect(() => {
        setHoverState(false)
      }, []);
    return (
        <Fellow>
            <FellowImageContainer>
                <FellowImage src={props.data.teamDetails.image.mediaItemUrl} hoverState={hoverState}></FellowImage>
                <OverlayContainer onMouseOver={toggleHoverIn} onMouseOut={toggleHoverOut}>
                    <Overlay hoverState={hoverState}>
                        <OverlayNameBlock>
                            {/* <OverlayName>{props.data.teamDetails.name}</OverlayName> */}
                            <OverlaySocialIcon href={props.data.teamDetails.linkedinUrl} target="_blank"><FontAwesomeIcon icon={faLinkedin}/></OverlaySocialIcon>
                        </OverlayNameBlock>
                        {props.data.teamDetails.description?
                            <Description>{props.data.teamDetails.description}</Description>
                        :''}
                        </Overlay>
                </OverlayContainer>
            </FellowImageContainer>
            
            <Name>{props.data.teamDetails.name}</Name>
            {props.data.teamDetails.designation?
                <Designation>{props.data.teamDetails.designation}</Designation>
                :''
            }   
        </Fellow>
    );
};

export default FellowTile;