import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionParagraph from "../components/SectionParagraph"
import MemberRow from "../components/MemberRow"
import FellowTile from "../components/FellowTile"
import Background from "../images/section-background.png"
import { graphql } from "gatsby"
import LogoBlock from "../components/LogoBlock"
import Favicon from "../images/w-health-favicon.png"
import ExpandBox from "../components/ExpandBox/ExpandBox"

const TeamSection = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
    position:relative;
    padding-bottom:80px;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
      
    }
    @media screen and (max-width: 479px) {      
        
    }
    `;

const TeamBackSection = styled.div`
    position:absolute;
    top:200px;
    left:0%;
    height:100%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const BackgroundOne = styled.div`
    width:80%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:500% 50%;
    height:100%;
    margin-right:auto;
    background-image:url(${Background});
    margin-bottom:300px;
`;
const BackgroundTwo = styled.div`
    background-size:cover;
    background-repeat: no-repeat;
    background-position:-500% 50%;
    height:100%;
    width:80%;
    margin-right:0;
    margin-left:auto;
    background-image:url(${Background});
    margin-bottom:300px;
`;
const TeamContentWrap = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    padding:0 2.5% 0 2.5%;;
    height:100%;
    @media screen and (max-width: 991px) {
      width:100%;
      margin-bottom:50px;
    }
    @media screen and (max-width: 479px) {
        margin-bottom:40px;
      }
`;
const LogoContainer = styled.div`
    width:50%;
    height:auto;
     z-index:0;
`;
const LogoImg = styled.img`
    width:auto;
    height:auto;
    margin:30px 0 0px 0;
    @media screen and (max-width: 991px) {
      margin:40px 0 0px 0;
  }
`;
const FellowsContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    padding:30px 2.5% 30px 2.5%;
    width:100%;
    height:auto;
    z-index:0;
    @media screen and (max-width: 479px) {
        padding:0px 2.5% 0px 2.5%;
    }
`;
const FellowRow = styled.div`
    margin:0px auto 0x auto;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
`;
const FellowGrid = styled.div`
    display:grid;
    grid-template-rows:auto;
    grid-template-columns:1fr 1fr 1fr 1fr;
    width:100%;
    margin:auto;
    grid-gap:2rem;
    padding:${props => props.grid ? '0px 2.5% 0px 2.5%' : '0px'};
    @media screen and (max-width: 991px) {
        grid-template-columns:1fr 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
        grid-template-columns:1fr 1fr;
    }
    @media screen and (max-width: 479px) {
        grid-template-columns:1fr 1fr;
        grid-gap:1rem;
        padding:${props => props.grid ? '20px 2.5% 0px 2.5%' : '0px'};
    }
`;
const Breadcrumb = styled.h1`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const SectionHeading = styled.h1`
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color:rgba(46,52,57,0.9);
    margin-top:60px;
    margin-bottom:${props => props.bottom || '10px'};
    position:relative;
    @media screen and (max-width: 991px) {
        margin-top:0px;
    }
    @media screen and (max-width: 479px) {
        font-size: 30px;
        margin-top:${props => props.mobileTop || '0px'} !important;
      }
`;
export default function Team({ data }) {
    const TeamData = data;
    const FilteredResults = (tag) => {
        const query = [tag]
        const filteredData =
            TeamData.allWpTeam.nodes.filter(team => {
                return (
                    team.teamTags.nodes[0].name.toString().toLowerCase().includes(query.toString().toLowerCase())
                )
            })
        return filteredData
    }
    return (
        <Layout>
            <Seo title="Team" url="https://whealthventures.com/team" image={Favicon} />
            <TeamSection>
                <TeamBackSection>
                    <BackgroundOne />
                    <BackgroundTwo />
                    <BackgroundOne />
                </TeamBackSection>
                <TeamContentWrap>
                    <LogoBlock></LogoBlock>
                    <Breadcrumb>Our Team</Breadcrumb>
                    <SectionHeading>Core Team</SectionHeading>
                    <SectionParagraph>Get to know those behind the impact our fund looks to create.</SectionParagraph>

                    <FellowGrid>
                        {FilteredResults("Core Team").map((member, index) => (
                            <ExpandBox data={member} key={"CoreTeam" + index}></ExpandBox>
                        ))}
                    </FellowGrid>
                </TeamContentWrap>

                {FilteredResults("Current Fellows").length > 0 ?
                    <>
                        <FellowsContainer>
                            <SectionHeading top={"0px"} bottom={"0px"}>Current Fellows</SectionHeading>
                        </FellowsContainer>
                        <FellowGrid grid={true}>
                            {FilteredResults("Current Fellows").map((member, index) => (
                                <ExpandBox data={member} key={"CurrentFellow" + index} />
                            ))}
                        </FellowGrid>
                    </>
                    : ""}
                {FilteredResults("Past Fellows").length > 0 ?
                    <div>
                        <FellowsContainer>
                            <SectionHeading top={"0px"} bottom={"0px"}>Past Fellows</SectionHeading>
                        </FellowsContainer>
                        <FellowGrid grid={true}>
                            {FilteredResults("Past Fellows").map((member, index) => (
                                <ExpandBox data={member} key={"PastFellow" + index} />
                            ))}
                        </FellowGrid>
                    </div>
                    : ""}


            </TeamSection>
        </Layout>
    )
}


export const query = graphql`
  query {
    allWpTeam(sort: {order: ASC, fields: date}) {
        nodes {
          teamDetails {
            description
            designation
            linkedinUrl
            name
            image {
              mediaItemUrl
            }
          }
          teamTags {
            nodes {
              name
            }
          }
        }
      }
  }
`